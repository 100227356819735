// This needs to be imported first
import './public-path'

// Polyfills
import 'focus-visible'
import 'core-js/modules/es.object.assign'
import 'core-js/modules/es.promise'
import 'core-js/modules/es.string.includes'
import 'scroll-behavior-polyfill'

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

// External dependencies
import 'bootstrap'
import $ from 'jquery'
import 'jquery-match-height'

// Expose jQuery globally
window.$ = window.jQuery = $

// Local modules
import { throttle } from './helpers'
import Animations from './animations'
import Header from './header'
import Home from './home'
import Rotate from './rotate'
import Parallax from './parallax'
import EventInquiry from './event-inquiry'

/**
 * INIT
 *
 * This function initializes all modules used on the site, try to keep it as
 * simple as possible moving any complicated logic into the modules itself
 */
const Init = function () {
  // Set scrollBehaviour here so the polyfill can pick it up
  document.documentElement.style.scrollBehavior = 'smooth'

  Animations.init()
  Header.init()
  Home.init()
  Rotate.init()
  Parallax.init()
  EventInquiry.init()

  /**
   * Expose all loaded packages globally under the `Bauwerk` domain for runtime
   * access. Make sure to inititalize all modules before this assignment.
   */
  window.Bauwerk = {
    Animations,
    Header,
    Home,
    Rotate,
    Parallax,
    EventInquiry
  }

  window.addEventListener('resize', onResize)
  window.addEventListener('scroll', onScroll)
}

// Initialize as soon as the DOM content has been loaded
document.addEventListener('DOMContentLoaded', Init)



const onResize = throttle(function() {
  Home.onScroll()
  Parallax.onScroll()
  EventInquiry.onScroll()
}, 250)

const onScroll = function () {
  Home.onScroll()
  Parallax.onScroll()
  EventInquiry.onScroll()
}
