const Rotate = function () {
  const selector = '[data-rotate]'

  let elements = []
  let instances = []

  function init() { 
    elements = [...document.querySelectorAll(selector)]

    if (!elements.length) return

    elements.forEach(element => {
      const words = JSON.parse(element.dataset.rotate)
      const instance = new TextRotator(element, words)
      instances.push(instance)
    })

    start()
  }
  
  function start() {
    instances.forEach(instance => instance.start())
  }
  
  function stop() {
    instances.forEach(instance => instance.stop())
  }
  
  return {
    init,
    start,
    stop
  }
}()

export default Rotate



function TextRotator(target, words, changeInterval = 2000, updateInterval = 80) {
  let index = 0, interval = undefined, timeout = undefined

  if (!words) { 
    words = JSON.parse(target.dataset.rotate)
  }

  function start() { 
    let word = words[index]
    let i = 0
    interval = setInterval(() => {
      let str = target.textContent

      while (str.length < word.length) str += ' '

      const ltr = word[i] || ' '
      const out = (str.slice(0,i) + ltr + str.slice(i+1)).trim()
      
      target.innerHTML = out
      
      if (++i === Math.max(word.length, str.length)) {
        clearInterval(interval)

        index = (index + 1) % words.length
        timeout = setTimeout(start, changeInterval)
      }
    }, updateInterval)
  }

  function stop() {
    clearInterval(interval)
    clearTimeout(timeout)
  }

  return { start, stop }
}
