import $ from 'jquery'

const presets = {
  "workshop": {
    "format": ["live"],
    "location": ["harbor"],
    "services": ["technic", "equipmentDecor", "catering"]
  },
  "conference": {
    "format": ["hybrid"],
    "location": ["cologne", "studio"],
    "services": ["technic", "equipmentDecor", "catering", "direction"]
  },
  "congress": {
    "format": ["digital"],
    "location": ["cologne", "studio"],
    "services": ["technic", "equipmentDecor", "catering", "direction", "attendeeManagement"]
  },
  "productpresentation": {
    "format": ["digital"],
    "location": ["cologne"],
    "services": ["technic", "equipmentDecor", "catering", "direction"]
  },
  "anniversary": {
    "format": ["live"],
    "location": ["cologne"],
    "services": ["technic", "equipmentDecor", "catering"]
  },
  "gettogether": {
    "format": ["live"],
    "location": ["cologne"],
    "services": ["technic", "equipmentDecor", "catering"]
  }
}

const EventInquiry = function () {

  const timeoutDuration = 2000

  let elements, timeout, $popoverElement

  const popoverOptions = id => ({
    template: $(`#${id}`).html(),
    content: $(`#${id} .popover-body`).html(),
    placement: 'top',
    offset: '100px, 0',
    trigger: 'manual',
    sanitize: false,
    html: true
  })

  function init() {
    elements = {
      form: document.getElementById('event-inquiry-eventBlocks'),
      inputs: {
        type: document.querySelectorAll('[name="--event-inquiry[type]"]'),
        format: document.querySelectorAll('[name="--event-inquiry[format]"]'),
        location: document.querySelectorAll('[name="--event-inquiry[location][]"]'),
        services: document.querySelectorAll('[name="--event-inquiry[services][]"]'),
      }
    }

    $(elements.inputs.type).on('change', onChange)
  }

  function onChange(event) {
    // Remove `.proposed` and `.hilite` class from all inputs
    for (const set in elements.inputs) {
      $(elements.inputs[set]).removeClass('proposed hilite')
    }

    // Destroy the popover
    if ($popoverElement) {
      $popoverElement.popover('dispose')
    }

    // Find the currently active preset
    const preset = presets[event.target.value]

    // Set `.proposed` class on each input in preset
    for (const set in preset) {
      const $res = $(elements.inputs[set]).filter(function () {
        return $.inArray($(this).val(), preset[set]) > -1
      }).addClass('proposed')


      if (set === 'format') {
        // Only initialize a new popup if 'format' has not already been chosen
        if (!$(elements.inputs[set]).is(':checked')) {

          $popoverElement = $res.first().popover(popoverOptions('popover2')).popover('show')

          $('[data-popover-accept]').one('click', onPopoverAccept)
          $('[data-popover-close]').one('click', onPopoverClose)

          // Hide on next click
          $('body').on('click', handleHidePopover)
        }
      }
    }
  }

  function onInit() {
    if (!$(elements.inputs.type).is(':checked')) {
      $(elements.inputs.type).addClass('hilite')

      $popoverElement = $(elements.inputs.type).first().popover(popoverOptions('popover1')).popover('show')

      $('[data-popover-close]').one('click', onPopoverClose)
    }
  }

  function onPopoverAccept() {
    $popoverElement.popover('hide')

    // Set all `.proposed` inputs as checked
    for (const set in elements.inputs) {
      $(elements.inputs[set]).each(function() {
        if ($(this).hasClass('proposed')) {
          $(this).prop('checked', true)
        }
      })
    }
  }

  function onPopoverClose() {
    $popoverElement.popover('hide')

    // Remove `.proposed` class from all inputs
    for (const set in elements.inputs) {
      $(elements.inputs[set]).removeClass('proposed')
    }
  }

  function handleHidePopover(event) {
    // Close popover and unbind event if click is outside the popover
    if (!$(event.target).parents().is('.popover')) {
      $popoverElement.popover('hide')
      $('body').off('click', handleHidePopover)
    }
  }

  function onScroll() {

    if (elements.form) {
      const formHeight = $(elements.form).height()
      const formOffsetTop = $(elements.form).offset().top

      const scrollTriggers = {
        top: formOffsetTop <= $(window).scrollTop() + formHeight / 2,
        bottom: formOffsetTop + formHeight >= $(window).scrollTop() + formHeight
      }

      if (scrollTriggers.top && scrollTriggers.bottom) {
        if (!timeout) {
          timeout = setTimeout(onInit, timeoutDuration)
        }
      } else {
        timeout = clearTimeout(timeout)
      }
    }
  }

  return {
    init,
    onScroll
  }
}()

export default EventInquiry
