import ScrollTrigger from '@terwanerik/scrolltrigger'

const Animations = function () {
  const selectors = {
    teaser: '.teaser',
    container: '.container, .container-fluid',
  }

  function init() {

    const trigger = new ScrollTrigger({
      trigger: {
        toggle: {
          class: {
            in: 'animateIn',
            out: 'animateOut'
          }
        }
      },
    })

    trigger.add(selectors.teaser, {
      once: false,
      offset: {
        viewport: {
          x: 0,
          y: trigger => trigger.visible ? 0 : 0.2
        }
      }
    })
  }

  return {
    init
  }
}()

export default Animations
