import * as culori from 'culori'

const Home = function() {

  let elements

  function init() {
    elements = {
      header: document.querySelector('.header'),
      video: document.querySelector('video'),
    }

    onScroll()
  }



  function onScroll() {
    if (elements.video && elements.header && elements.header.classList.contains('home')) {
      const isVideoOverscrolled = window.pageYOffset > elements.video.offsetHeight
      elements.header.classList[isVideoOverscrolled ? 'remove' : 'add']('hidden')
    }
  }

  return {
    init,
    onScroll
  }
}()

export default Home
