import $ from 'jquery'

const Header = function () {

  let elements

  function init() {
    elements = {
      header: document.querySelector('.header'),
      logo: document.querySelector('.navbar-brand object'),
    }

    if (elements.header) {
      $(document).on({
        'show.bs.modal': addHeaderClass,
        'hide.bs.modal': removeHeaderClass
      })
    }
  }

  function destroy() {
    if (elements.header) {
      $(document).off({
        'show.bs.modal': addHeaderClass,
        'hide.bs.modal': removeHeaderClass
      })
    }
  }

  function addHeaderClass(event) {
    if (event.target.id === 'menu') {
      elements.header.classList.add('is-open')
    }
  }

  function removeHeaderClass(event) {
    if (event.target.id === 'menu') {
      elements.header.classList.remove('is-open')
    }
  }

  return {
    init,
    destroy,
  }
}()

export default Header
